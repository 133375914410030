// ** React Imports
import { Suspense, lazy } from "react";
import ReactDOM from "react-dom";

// ** Redux Imports
import { Provider } from "react-redux";

// ** Intl & ThemeColors Context
import { ToastContainer } from "react-toastify";
import { ThemeContext } from "@src/utility/context/ThemeColors";


import { configureStore } from "@reduxjs/toolkit";

// ** Spinner (Splash Screen)
import Spinner from "@src/@core/components/spinner/Fallback-spinner";

// ** Ripple Button
import "@src/@core/components/ripple-button";

// ** PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// ** React Toastify
import "@src/@core/scss/react/libs/toastify/toastify.scss";

// ** Core styles
import "@src/@core/assets/fonts/feather/iconfont.css";
import "@src/@core/scss/core.scss";
import "@src/assets/scss/style.scss";

// ** App styles
import "./index.scss";

// I18n Configuration
import "@progress/kendo-theme-bootstrap/dist/all.css";
// ** Service Worker
import * as serviceWorker from "./serviceWorker";
import appConfig from "./configs/appConfig";
import 'olympus-frontend/src/@core/scss/core.scss'
import './hermes/components/component_extension.scss'
import initialization from '@src/configs/il8n/loader'
import lazyWithRetry from 'olympus-frontend/src/components/lazyAppErrorRetry'
import {resolvingTenant} from "./hermes/components/utils";
import 'react-tooltip/dist/react-tooltip.css'
import { ClearCacheProvider } from 'react-clear-cache';

// ** Lazy load app
const LazyApp = lazyWithRetry(() => import("./App"));

const store = configureStore({
  reducer: appConfig.reduxReducers,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    });
  },
});

// resolvingTenant().default_lang
const i18n = initialization('it')

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={null}>
      {/* <AbilityContext.Provider value={ability}> */}
        {/* <ThemeContext> */}
        <ClearCacheProvider duration={45000}>
            <LazyApp/>
            <ToastContainer newestOnTop/>
        </ClearCacheProvider>
        {/* </ThemeContext> */}
      {/* </AbilityContext.Provider> */}
    </Suspense>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
