import {useState, useEffect, Fragment, useRef} from "react";
import {getUserData} from "olympus-frontend/src/utility/Utils";
import {useTranslation} from "react-i18next";
import {Input} from "@progress/kendo-react-inputs";
import {DropDownButton} from "@progress/kendo-react-buttons";
import {EvolvereConfig, SEAConfig, SCCConfig, DemoFieldStream} from '../../configs/tenants/tenants'
import axios from "axios";
import appConfig from '../../configs/appConfig'
import {getComunications} from "../comunication/datasource";
import moment from 'moment';

const userPath = appConfig.API.usersAPI


export const updateComunicationBadge = async(t, i18n) => {
    const response_ = await setTimeoutMilliSeconds(350)
    const querySelector = document.querySelector(".main-menu-content > .navigation.navigation-main > li[data-id='comunication_list'] .badge")
    const querySelectorPopup = document.querySelector("#communications-alert")?.getAttribute('communication_ids');

    const response = await getComunications(t, i18n, {}, true, undefined, true)
    // See if there is a new notification, watching recipients.user.read_timestamp
    const newNotification = response.comunications
    const highComunication = newNotification.filter((x) => x.priority === 'HIGH')

    if(highComunication.length > 0) {
        const highComunicationIds = highComunication.map((x) => x.id)
        document.querySelector("#communications-alert").setAttribute('communication_ids', highComunicationIds.join(','))
        document.querySelector("#communications-alert").classList.remove("invisible")
    }
    else {
        document.querySelector("#communications-alert").setAttribute('communication_ids', '')
        document.querySelector("#communications-alert").classList.add("invisible")
    }

    if (!querySelector)
        return;
    if (newNotification.length > 0) {
        // Target the .main-menu-content > .navigation.navigation-main > li#comunication > .badge
        // Set it up to show and its text to the number of new notifications with DOM
        querySelector.style.display = "block"
        if (newNotification.length > 99)
            querySelector.innerText = "99+"
        else
            querySelector.innerText = newNotification.length
    } else {
        // Target the .main-menu-content > .navigation.navigation-main > li#comunication > .badge
        // Set it to hide with DOM
        const querySelectorEl = document.querySelector(".main-menu-content > .navigation.navigation-main > li[data-id='comunication_list'] .badge")
        if (querySelectorEl) {
            querySelectorEl.style.display = "none"
            querySelectorEl.innerText = "0"
        }
    }

}

/**
 * KendoReact ExportExcel utility:
 * @param dataSourceAPI: The given API for fetching data, it will be called on demand
 * @param dataSourceArgs: given API parameters as arrayOf(any)
 * @param columns: given Excel columns to export to,
 * @param _export: export Reference (useRef) to KendoExcel
 * @param dataSourceResponseKey: if the given API response must be parsed
 * @returns {Promise<*>}
 */
export const getExportData = async (dataSourceAPI, dataSourceArgs, columns, _export, dataSourceResponseKey) => {
    try {
        if (!_export.current)
            return Promise.reject("UNMOUNT")

        let response_ = await dataSourceAPI.apply(null, dataSourceArgs)

        // Waiting for blob://uri
        let data_uri = await _export.current.toDataURL(dataSourceResponseKey ? response_[dataSourceResponseKey] : response_, columns)
        return _export.current.saveFile(data_uri)
    } catch (e) {
        if (!_export || !_export.current )
            return Promise.reject("UNMOUNT")
        return Promise.reject(e)
    }
}

export const getUserGridColumns = async (userId = -1, tableName = 'string') => {
	try {
		let response = await axios.get(`${userPath}/${userId}/ui_config`)
		if (
            response.data !== null &&
			response.data.grids &&
			response.data.grids[tableName] &&
			response.data.grids[tableName].columns &&
			Array.isArray(response.data.grids[tableName].columns)
		) {
			return Promise.resolve({
				columns: response.data.grids[tableName].columns,
				found: true
			})
		}
		else
			return Promise.resolve({
			    columns: [],
			    found: false
		    })
	} catch (e) {
		return Promise.reject(new Error(`Exception Grid Columns for user:${userId}, gridname:${tableName} table, from API ${e}`))
	}
}

export const createFormData = (JSONObject) => {
    const params = new FormData();
    Object.keys(JSONObject).forEach((x) => {
        if (x === 'electronic_load_ids') {
            JSONObject[x].forEach((electronic_load_id) => params.append(x, electronic_load_id))
        } else if (x.includes('_json')) {
            params.append(x, JSON.stringify(JSONObject[x]))
        } else params.append(x, JSONObject[x])
    })
    return params
}

//TODO: Migrate to olympus-frontend
export const getReactSelectFormattedObject = (
    arrayOfElements,
    valueKey,
    valueLabel,
    missingKeyValue = false,
    extendWithProps = false,
    translateHook = null) => {
    // If array contains List[str] or Enums assign a integer id
    let transformedArray = []

    if (missingKeyValue) {
        let index = -1
        transformedArray = arrayOfElements.map((x) => {
            index += 1
            return (extendWithProps) ?
                {...x, value: index, label: x[valueLabel]} :
                {value: index, label: x[valueLabel]}
        })
    } else {
        transformedArray = arrayOfElements.map((x) => {
            return (extendWithProps) ?
                {...x, value: x[valueKey], label: x[valueLabel]} :
                {value: x[valueKey], label: x[valueLabel]}
        })
    }
    // Check if need translation
    if (translateHook)
        transformedArray = transformedArray.map((x) => {
            return {...x, label: translateHook(x.label)}
        })
    return transformedArray
}

/**
 Use the current url to get the current 'app' configuration from the folder configs/tenants/{tenantName}
 Behaviour:
 1. Get from url the tenant name (e.g. https://hermes.m2d.tech, https://hermes-sea.m2d.tech)
 2. Get the tenant configuration from the folder configs/tenants/{tenantName}
 3. Return the tenant configuration to the App component
 **/
export const resolvingTenant = () => {
	const url = window.location.hostname;
    const tenantDomain = `${window.location.protocol}//${window.location.host}`

	// If development
	if (
		['http://localhost','192.168.1.5', 'localhost', '127.0.0.1', '0.0.0.0'].filter((localhost_like_str) =>
			window.location.hostname.includes(localhost_like_str)
		).length > 0
	) {
		// Get Evolvere as default
		return EvolvereConfig(tenantDomain).app
    }
    // FieldStream Case, excluded from Hermes tenants
    if (url.includes('demo.fieldstream.pro'))
        return DemoFieldStream(tenantDomain).app

    const tenantName = url.split('.m2d.tech')[0]
    switch (tenantName) {
        case "hermes":
        case "hermes-dev":
        case "hermes-acc":
        case "hermes-demo":
            return EvolvereConfig(tenantDomain).app
        case "hermes-sea":
            return SEAConfig(tenantDomain).app
        case "hermes-scc":
            return SCCConfig(tenantDomain).app
        default:
            throw new Error('There are not an available configuration for this app url!')
    }
}

export const getUserRole = () => {
    let userData = getUserData()
    if (userData.groups.length > 0 || userData.is_superuser)
        if (userData.groups.map((x) => x.name).includes('administrator') || userData.is_superuser) return 'admin'
        else if (userData.groups.map((x) => x.name).includes('planner')) return 'planner'
        else if (userData.groups.map((x) => x.name).includes('technician')) return 'technician'
        else return ''
    return ''
}
// TODO: Migrate su olympus-frontend, insert doc
export const setTimeoutMilliSeconds = async (timeoutMilliseconds) => {
    await new Promise(resolve => setTimeout(resolve, timeoutMilliseconds))
}

/**
 * Return olympus-fronend based validation errors [TRANSLATED].
 * it can handle also RAINBOW-CRM ERRORS
 * Rainbow Errors
 *   Status: 424
 *   Response Example: {"details":[{"code":7009,"message":"Il planning indicato non è presente a sistema"}]}
 * @param  {responseData} response from API handler.
 * @return {Array[ {name: '', msg: ''} ] || String }
 */
export const settingAPIValidationErrors = (responseData) => {
    const details = responseData.details || responseData.detail;
    let formatted_errors = []

    if (typeof details === 'string')
        return details

    if (Array.isArray(details)) {
        // Build up formatted_errors objects
        formatted_errors = details.map((detailLocation) => {
            return {
                name: detailLocation.loc || `${detailLocation.code}`,
                platform: detailLocation.code ? 'Rainbow' : 'Hermes',
                msg: ''
            }
        })
        details.forEach((validationField) => {
            // Msg can be array
            if (Array.isArray(validationField.msg)) {
                formatted_errors.find((detailLocation) =>
                    detailLocation.name === validationField.loc ||
                    detailLocation.name === `${validationField.code}`)
                    .msg = validationField.msg.join(', ')
            } else
                formatted_errors.find((detailLoc) =>
                    detailLoc.name === validationField.loc ||
                    detailLoc.name === `${validationField.code}`)
                    .msg = validationField.msg
        })
    }
    return formatted_errors.map((errors_) => {
        return {
            ...errors_,
            msg: errors_.platform === 'Rainbow' ? `Rainbow Error: ${errors_.msg}` : errors_.msg,
        }
    })
}

export const parseKendoGridDataState = (dataState, customQueryParams = undefined, parseFilterTextColumns = []) => {
    let string

    if (!customQueryParams)
        string = `?take=${dataState.take}&skip=${dataState.skip}`
    else
        string = `${customQueryParams}`
    if (dataState.filter) {
        if (parseFilterTextColumns.length > 0) {
            // Find if filters have a filter included into parseFilterTextColumns and if they have operator 'isnull' and value null
            // If yes, for each of one add another filter with operator 'eq' and value '' in logic OR with the previous one

            // Example1: {logic: "and", filters: [{field: "main_contractor.name", operator: "eq", value: 100 }]}
            // Result1: {logic: "or", filters: [{field: "main_contractor.name", operator: "eq", value: 100 }, {field: "main_contractor.name", operator: "eq", value: "EMPTY" }]}

            // Example2: {logic: "and", filters: [{field: "main_contractor.name", operator: "eq", value: 100 }, {field: "id", operator: "eq", value:1}]}
            // Result2: {logic: "and", filters: [{ logic: "or", filters: [{ field: "main_contractor.name", operator: "isnull", value: null },{ field: "main_contractor.name", operator: "eq", value: "EMPTY" }]},{field: "id", operator: "eq", value:1}]}

            // Example3: {logic: "and", filters: [{field: "region", operator: "eq", value: 100 }, {field: "id", operator: "eq", value:1}]}
            // Result3: {logic: "and", filters: [{ logic: "or", filters: [{ field: "region", operator: "isnull", value: null },{ field: "region", operator: "eq", value: "EMPTY" }]},{field: "id", operator: "eq", value:1}]}

            let filters_ = dataState.filter.filters

            if (filters_.length > 0) {
                for (let i = 0; i < filters_.length; i++) {
                    if (
                        parseFilterTextColumns.includes(filters_[i].field) &&
                        filters_[i].operator === 'isnull' &&
                        filters_[i].value === null
                    ) {
                        dataState.filter.filters[i] = {
                            logic: 'or',
                            filters: [
                                {
                                    field: filters_[i].field,
                                    operator: 'eq',
                                    value: ''
                                },
                                filters_[i]
                            ]
                        }
                    }
                }
            }
        }
        string += `&filter=${encodeURIComponent(JSON.stringify(dataState.filter))}`
    }
    if (dataState.sort)
        string += `&sort=${encodeURIComponent(JSON.stringify(dataState.sort))}`
    return string
}

export const checkEmptyFields = (dataState, translateHook) => {
    // Filters are done from backend, forward correct fields and values to ensure working well.
    // company_type is translated (FE purpose) => must be untranslated
    // company_type can be null (FE has its i18n value: Empty or Vuoto) => Translate
    // Relationship values with ".", if they are null are forwarded as foreign_key field
    let dataState_ = JSON.parse(JSON.stringify(dataState));
    if (!dataState_.filter || (Array.isArray(dataState_.filter.filters) && dataState_.filter.filters.length === 0))
        return dataState_
    let this_filter = dataState_.filter.filters
    if (this_filter) {
        do {
            this_filter = this_filter[0].filters
            if (Array.isArray(this_filter) && this_filter.length > 0 && !this_filter.filters) {
                // can substitute strings
                for (let i = 0; i < this_filter.length; i++) {
                    let this_element_filtered = this_filter[i]
                    // Check empty value and reformat to "null"
                    if (translateHook("EMPTY") === this_element_filtered.value) {
                        // Check if field is a relationship field
                        if (this_element_filtered.field.includes('.'))
                            this_element_filtered.field = this_element_filtered.field.split('.')[0]
                        this_element_filtered.value = null
                    }
                }
            }
        } while (Array.isArray(this_filter.filters) && this_filter.filters.length > 0)
    }
    return dataState_
}

// TODO: set datatestid for inputs on grids?

export const SET_GRID_ATTACHMENTS = (gridRef, baseValue = 'base', columnFields = []) => {

    if (gridRef.current) {
        let indexPages = 0
        const gridElement = gridRef.current.element
        const headerIcons = gridRef.current.headerRef.current.element.querySelectorAll('.k-grid-column-menu.k-grid-filter')
        const pageSizeIcons = gridRef.current.element.querySelectorAll('.k-pager-numbers-wrap .k-pager-numbers li')
        const pageSizeChooserDropdown = gridRef.current.element.querySelector('.k-pager-sizes .k-widget.k-dropdown')

        if (gridElement) gridElement.setAttribute('data-testid', `${baseValue}-grid`)
        if (pageSizeChooserDropdown) pageSizeChooserDropdown.setAttribute('data-testid', `${baseValue}-pageSize-dropdown`)
        // Must specify fields!
        if (headerIcons.length && columnFields.length > 0)
            headerIcons.forEach((node, indexNode) => {
                if (columnFields[indexNode] !== undefined)
                    node.setAttribute('data-testid', `${baseValue}-filter-${columnFields[indexNode]}`)
            })
        if (pageSizeIcons.length) pageSizeIcons.forEach(node => {
            indexPages += 1
            node.setAttribute('data-testid', `${baseValue}-pageSize-${indexPages}`)
        })
    }
}

export const KendoEqualsToTextFilter = (props) => {

    const {i18n, t} = useTranslation()
    const [operators, setOperators] = useState(() => {
        return [{text: t('Is equal to'), operator: 'eq'}]
    })
    const [selectedOperator, setSelectedOperator] = useState(operators[0])
    const buttonEl = useRef(null);

    const onChange = (event) => {
        if (event.target.value === null || event.target.value === '')
            buttonEl.current.click()
        else
            props.onChange({
                value: event.target.value,
                operator: selectedOperator.operator,
                syntheticEvent: event.syntheticEvent,
            });
    };

    const onClearButtonClick = (event) => {
        event.preventDefault();
        props.onChange({
            value: null,
            operator: '',
            syntheticEvent: event,
        });
    };

    const value = props.value;
    return (
        <div className={"d-flex"} style={{gap: '0.25rem'}}>
            <Input
                style={{width: '80%'}}
                value={value}
                onChange={onChange}
            />
            <div className={"filter-dropdowns d-flex"}>
                <DropDownButton
                    className="buttons-container-button"
                    items={operators}
                    item={(props) =>
                        <option
                            className={`${selectedOperator.text === props.item.text ? 'text-primary k-item' : 'k-item'}`}
                            value={props.item.text} {...props}>
                            {props.item.text}
                        </option>
                    }
                    icon="filter"
                    title={t("Choose Operator")}
                    textField="text"
                />
                <button
                    ref={buttonEl}
                    className="k-button k-button-icon k-clear-button-visible"
                    title={t("Clear2")}
                    onClick={onClearButtonClick}
                >
                    <span className="k-icon k-i-filter-clear"/>
                </button>
            </div>
        </div>
    );
};

export const KendoContainsTextFilter = (props) => {

    const {i18n, t} = useTranslation()
    const [operators, setOperators] = useState(() => {
        return [{text: t('Contains'), operator: 'contains'}]
    })
    const [selectedOperator, setSelectedOperator] = useState(operators[0])
    const buttonEl = useRef(null);

    const onChange = (event) => {
        if (event.target.value === null || event.target.value === '')
            buttonEl.current.click()
        else
            props.onChange({
                value: event.target.value,
                operator: selectedOperator.operator,
                syntheticEvent: event.syntheticEvent,
            });
    };

    const onClearButtonClick = (event) => {
        event.preventDefault();
        props.onChange({
            value: null,
            operator: '',
            syntheticEvent: event,
        });
    };

    const value = props.value;
    return (
        <div className={"d-flex"}>
            <Input
                style={{flex: 1}}
                value={value}
                onChange={onChange}
            />
            <div className={"filter-dropdowns d-flex"}>
                <button
                    ref={buttonEl}
                    className="k-button k-button-icon k-clear-button-visible"
                    style={{marginLeft: '0.3rem'}}
                    title={t("Clear2")}
                    onClick={onClearButtonClick}
                >
                    <span className="k-icon k-i-filter-clear"/>
                </button>
            </div>
        </div>
    );
};

export const GridInjector = (props) => {
    const {
        baseGridName,
        gridReference,
        columnFields
    } = props

    // TODO: columns will be dynamics based on user preference
    useEffect(() => {
        setTimeout(() => {
            SET_GRID_ATTACHMENTS(gridReference, baseGridName, columnFields)
        }, 750)
    }, [])

    return (
        <Fragment/>
    )
}

export const getStrDayOfWeek = (weekday = -1, translateHook = undefined) => {
    let item = ''
    switch (weekday) {
        case 0:
            item = translateHook('Monday')
            break
        case 1:
            item = translateHook("Tuesday")
            break
        case 2:
            item = translateHook("Wednesday")
            break
        case 3:
            item = translateHook("Thursday")
            break
        case 4:
            item = translateHook("Friday")
            break
        case 5:
            item = translateHook("Saturday")
            break
    }
    return item
}

// Get the number of all days between (Monday - Friday) in a given year-month
export const getLVDaysInMonth = (year = 2024, month = 1) => {
    const monthDays_ = moment(`${year}-${month}-01`).daysInMonth()
    const days_ = Array.from({length: monthDays_}, (_, i) => i + 1)

    const workingDays_ = days_.filter(day => {
        const weekDay = moment(`${year}-${month}-${day}`).isoWeekday();
        return weekDay >= 1 && weekDay <= 5; // L-V
    }).length;

    return workingDays_
}

// Get the number of all days between (Sat - Sund) in a given year-month
export const getSDDaysInMonth = (year = 2024, month = 1) => {
    const monthDays_ = moment(`${year}-${month}-01`).daysInMonth();
    const days_ = Array.from({ length: monthDays_ }, (_, i) => i + 1);

    const workingDays_ = days_.filter(day => {
        const weekDay = moment(`${year}-${month}-${day}`).isoWeekday();
        return weekDay === 6 || weekDay === 7; // Saturday or Sunday
    }).length;

    return workingDays_;
}

